import React from "react"
import Link from "gatsby-link"
import logo from "../../images/waltz-logo.png"
import Scrollspy from "react-scrollspy-highlight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      showMenuClass: "",
    }
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this)
  }

  toggleMenuMobile() {
    if (this.state.showMenuClass === "") {
      this.setState({
        showMenuClass: "show",
      })
    } else {
      this.setState({
        showMenuClass: "",
      })
    }
  }

  render() {
    return (
      <>
        <section id="header" className="hero header">
          <div className="container is-fluid">
            <div className="columns margin-0">
              <div className="column is-4 navbar is-left padding-0">
                <Scrollspy
                  items={["intro", "about_us_page", "proizvodi", "blog_page"]}
                  currentClassName="is-current"
                >
                  <li>
                    <Link to="/en/">Home</Link>
                  </li>
                  <li>
                    <Link to="/en/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/en/#products">Our Products</Link>
                  </li>
                  <li>
                    <Link to="/en/blog">Blog</Link>
                  </li>
                </Scrollspy>
              </div>
              {/*LEFT NAVBAR END */}
              <div className="column is-4 logo is-center padding-0">
                <Link to="/">
                  <img src={logo} alt="Waltz" />
                </Link>
              </div>
              {/* LOGO END */}
              <div className="column is-4 navbar is-right padding-0">
                <Scrollspy
                  items={["distribution_page", "contact_page"]}
                  currentClassName="is-current"
                  className="is-right"
                >
                  <li>
                    <Link to="/en/distribution">Distribution</Link>
                  </li>
                  <li>
                    <Link to="/en/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/">SR</Link>
                  </li>
                </Scrollspy>
              </div>
              {/*LEFT NAVBAR END */}
            </div>
            {/* COLUMNS END */}
          </div>
          {/* CONTAINER END */}
        </section>
        {/* HEADER SECTION END */}

        <section id="header-mobile" className="hero header-mobile">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Waltz" />
              </Link>
            </div>
            <span
              className="mobile-menu-open"
              onClick={this.toggleMenuMobile.bind(this)}
            >
              <FontAwesomeIcon icon={["fas", "bars"]} aria-hidden="true" />
            </span>
            <div
              className={"mobile-menu-background " + this.state.showMenuClass}
            >
              <div className="navbar">
                <span
                  className="mobile-menu-close"
                  onClick={this.toggleMenuMobile.bind(this)}
                >
                  <FontAwesomeIcon icon={["fas", "times"]} aria-hidden="true" />
                </span>
                <Scrollspy
                  items={[
                    "intro",
                    "about_us_page",
                    "products",
                    "blog_page",
                    "distribution_page",
                    "contact_page",
                  ]}
                  currentClassName="is-current"
                >
                  <li>
                    <Link to="/en/">Home</Link>
                  </li>
                  <li>
                    <Link to="/en/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/en/#products">Our Products</Link>
                  </li>
                  <li>
                    <Link to="/en/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/en/distribution">Distribution</Link>
                  </li>
                  <li>
                    <Link to="/en/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/">SR</Link>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Header
